import { FC } from "react";
import { IRating } from "../../interfaces";
import { Badge, Button, Col, Row } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import "./index.css";

import {
  AiOutlineCheckCircle,
  AiOutlineInfoCircle,
  AiOutlineLike,
} from "react-icons/ai";
import { MdOutlineGppGood } from "react-icons/md";

interface IProps {
  rating: IRating;
  number: number;
}

export const Rating: FC<IProps> = ({ rating, number }) => {
  return (
    <div className="rating">
      <Row>
        <Col xl={3} md={6}>
          <a
            rel="noopener noreferrer"
            href={rating.url}
            className="rating__preview"
            target="_blank"
          >
            <Badge className="rating__place" bg="secondary">
              {number}-oe место
            </Badge>
            {number < 3 ? (
              <MdOutlineGppGood
                title="Верифицирован"
                size={40}
                className="rating__cup"
              />
            ) : null}
            <img
              className="rating__image"
              src={rating.image}
              alt={rating.name}
            />
          </a>
        </Col>
        <Col md={6} xl={2}>
          <div className="rating__rating">
            <a
              rel="noopener noreferrer"
              href={rating.url}
              className="rating__name"
              target="_blank"
            >
              {rating.name}
            </a>
            <div className="rating__stars">
              <StarRatings
                rating={rating.rating.value}
                starEmptyColor="#ececec"
                starSpacing="1px"
                starDimension="20px"
                starRatedColor="rgb(255 171 56)"
                numberOfStars={5}
                name="rating"
              />

              <span className="rating__values">
                {rating.rating.value.toFixed(1)}/5.0
              </span>
            </div>
            <span className="rating__count">{rating.rating.count} оценок</span>
            {rating.good_return ? (
              <div className="return">
                <AiOutlineCheckCircle size={20} className="return__icon" />
                <span className="return__text">{rating.good_return_text}</span>
              </div>
            ) : null}
          </div>
        </Col>
        <Col xl={3} md={6}>
          <div className="rating__info">
            {rating.bonuses.map((bonus, index) => (
              <span className="rating__item" key={index}>
                <AiOutlineLike size={18} className="rating__item_icon" />
                {bonus}
              </span>
            ))}

            {rating.show_payment && <div className="rating__payment">
              <span className="rating__payment__title">
                {rating.payment_text}
              </span>

              <div className="rating__payment__icons">
                <img src="./assets/images/logos/mir.png" alt="Мир" title="Мир"/>
                <img
                    src="./assets/images/logos/sber.png"
                    alt="Сбербанк"
                    title="Сбербанк"
                />
                <img
                    src="./assets/images/logos/visa.jpg"
                    alt="Visa"
                    title="Visa"
                />
                <img
                    src="./assets/images/logos/mc.jpg"
                    alt="Master Card"
                    title="Master Card"
                />
              </div>
            </div>}
          </div>
        </Col>
        <Col xl={2} md={6}>
          <div className="rating__info">
            {rating.info.map((item, index) => (
                <span className="rating__item" key={index}>
                <AiOutlineInfoCircle
                    size={18}
                    className="rating__item_icon rating__item_icon_gray"
                />
                  {item}
              </span>
            ))}
          </div>
        </Col>
        <Col lg={12} md={12} xl={2}>
          <div className="rating__buttons">
            <Button
                rel="noopener noreferrer"
                className="rating__button"
                href={rating.url}
                target="_blank"
                variant="success"
            >
              {rating.primary_button_text}
            </Button>
            <Button
              size="sm"
              href={rating.url}
              rel="noopener noreferrer"
              variant="link"
              target="_blank"
              className="rating__button_link"
            >
              {rating.secondary_button_text}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
