import { FC } from "react";
import { Container } from "react-bootstrap";
import "./index.css";
import {IData} from "../../interfaces";

interface IProps {
    data: IData;
}

export const Header: FC<IProps> = ({ data }) => (
  <div className="header">
    <Container>
      <h2 className="header__title">
          {data.heading}
      </h2>
    </Container>
  </div>
);
