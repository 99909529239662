import React, {FC, Fragment, useEffect, useState} from "react";
import ReactDOM from "react-dom/client";
import { Home } from "./pages/home";
import "bootstrap/dist/css/bootstrap.min.css";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import "./index.css";
import {Spinner} from "./components/spinner";
import {IData} from "./interfaces";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


const App: FC = () => {
    const [data, setData] = useState<IData | null>(null);

    useEffect(() => {
        fetch("./assets/json/ratings.json").then((response) => {
            response.json().then(setData);
        });
    }, []);

    return data
        ?
        <Fragment>
            <Header data={data} />
            <Home data={data} />
            <Footer data={data} />
        </Fragment>
        :
        <Spinner />
}

root.render(
  <React.StrictMode>
      <App/>
  </React.StrictMode>
);
