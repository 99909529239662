import { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Rating } from "../../components/rating";
import {IData, IRating} from "../../interfaces";
import { Spinner } from "../../components/spinner";

interface IProps {
  data: IData;
}

export const Home: FC<IProps> = ({ data }) => {
  return (
    <Container>
      <Row>
        <Col>
          {!!data.text && <p className="mb-5">{data.text}</p>}


          {data.ratings.length ? (
            <div>
              {(data.ratings as IRating[]).map((rating, index) => (
                <Rating key={index} rating={rating} number={index + 1} />
              ))}
            </div>
          ) : (
            <Spinner />
          )}
        </Col>
      </Row>
    </Container>
  );
};
