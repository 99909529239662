import { FC } from "react";
import "./index.css";

export const Spinner: FC = () => {
  return (
    <div className="spinner">
      <div className="lds-circle">
        <div></div>
      </div>
      <span>Загрузка ...</span>
    </div>
  );
};
