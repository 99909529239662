import { FC } from "react";
import "./index.css";
import { Container } from "react-bootstrap";
import {IData} from "../../interfaces";

interface IProps {
    data: IData;
}


export const Footer: FC<IProps> = ({ data }) => (
  <div className="footer">
    <Container>
      <span>{data.copyright}</span>
    </Container>
  </div>
);
